.ri-arrow-left-line {
    font-size: 25px;
}

.feather {
    stroke: var(--primary-color);
}

.test-screen {
    z-index: 10000 !important;
    right: 0 !important;
}

.w-100 {
    width: 100%;
}

.d-flex {
    display: flex;
}

.card {
    background: white;
    border-radius: 6px;
    padding: 32px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
}

.card.card--mb-20 {
    margin-bottom: 20px;
}

.card.card--m-0 {
    margin: 0;
}

.card.card--p-0 {
    padding: 0;
}

.icon {
    margin-bottom: 16px;
}

.icon__background {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 12px;
}

.icon--svg {
    width: 24px;
    height: 24px;
}

.icon--svg path {
    fill: var(--primary-color);
}

.title-wrapper,
.title-wrapper .title-wrapper__title,
.title-wrapper .title-wrapper__subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.title-wrapper {
    flex-direction: column;
}

.title-wrapper .title-wrapper__title {
    margin-bottom: 6px;
}

.title-wrapper .title-wrapper__title span {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: #030F16;
}

.title-wrapper .title-wrapper__subtitle span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #959595;
}

.button--no-style {
    border: none;
    cursor: pointer;
    outline: inherit;
}

.screen {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    position: absolute;
    width: 100%;
    top: 0;
    /* right: -100%; */
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

.widget-footer.light-bg {
    z-index: 10;
}

.button--hover-animation:hover {
    transform: scale3d(1.03, 1.03, 1.03);
    transition: transform .5s;
}

/* Style Datepicker */
.flatpickr-day.selected {
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.flatpickr-input {
    -webkit-appearance: none;
}

.backOneStep__btn {
    background: #eeeeee;
    border-radius: 10px;
    color: #7a7a7a;
    cursor: pointer;
    border: 1px solid transparent;
    min-width: 3rem;
    border-radius: 25px;
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}

.backOneStep__btn.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.overflow-auto {
    overflow: auto;
}

.overflow-auto::-webkit-scrollbar {
    display: none;
}
.desktop-ver .screen__content  {
    flex: 1;
}

.desktop-ver .notfound__container {
    flex-direction: row;
}

.desktop-ver .notfound__container .screen__content {
    align-items: center;
    justify-content: center;
}