.ri-arrow-left-line {
  font-size: 25px;
}

.feather {
  stroke: var(--primary-color);
}

.test-screen {
  z-index: 10000 !important;
  right: 0 !important;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.card {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  background: #fff;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  display: flex;
}

.card.card--mb-20 {
  margin-bottom: 20px;
}

.card.card--m-0 {
  margin: 0;
}

.card.card--p-0 {
  padding: 0;
}

.icon {
  margin-bottom: 16px;
}

.icon__background {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
}

.icon--svg {
  width: 24px;
  height: 24px;
}

.icon--svg path {
  fill: var(--primary-color);
}

.title-wrapper, .title-wrapper .title-wrapper__title, .title-wrapper .title-wrapper__subtitle {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.title-wrapper {
  flex-direction: column;
}

.title-wrapper .title-wrapper__title {
  margin-bottom: 6px;
}

.title-wrapper .title-wrapper__title span {
  color: #030f16;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.title-wrapper .title-wrapper__subtitle span {
  color: #959595;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.button--no-style {
  cursor: pointer;
  outline: inherit;
  border: none;
}

.screen {
  height: 100%;
  z-index: 1;
  width: 100%;
  flex-direction: column;
  animation-duration: .4s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  display: flex;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.widget-footer.light-bg {
  z-index: 10;
}

.button--hover-animation:hover {
  transition: transform .5s;
  transform: scale3d(1.03, 1.03, 1.03);
}

.flatpickr-day.selected {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.flatpickr-input {
  -webkit-appearance: none;
}

.backOneStep__btn {
  color: #7a7a7a;
  cursor: pointer;
  min-width: 3rem;
  min-height: 3rem;
  -webkit-tap-highlight-color: transparent;
  background: #eee;
  border: 1px solid #0000;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.backOneStep__btn.disabled {
  opacity: .5;
  pointer-events: none;
}

.overflow-auto {
  overflow: auto;
}

.overflow-auto::-webkit-scrollbar {
  display: none;
}

.desktop-ver .screen__content {
  flex: 1;
}

.desktop-ver .notfound__container {
  flex-direction: row;
}

.desktop-ver .notfound__container .screen__content {
  justify-content: center;
  align-items: center;
}

/*# sourceMappingURL=index.373f8843.css.map */
